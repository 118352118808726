import favicon from "../assets/images/FPHand/fphand.ico";
import logo192 from "../assets/images/FPHand/logo.png";
import logoCBHBTexto from "../assets/images/FPHand/logo_horizontal.png";
import logo from "../assets/images/FPHand/logo.png";
import footerBG from "../assets/images/FPHand/footer_bg.jpg";
import logoBigmidia from "../assets/images/bigmidia_azul.png";

export const metaFPHAND = {
  descricao_estabelecimento: "Federação Paulista de Handebol",
  siteTitle: "FPHAND | Federação Paulista de Handebol",
  nomeFooter: "FPHAND",
  siteDescription: "Site Oficial da Federação Paulista de Handebol",
  siteImg: logo192,
  logo: logo,
  logoMobile: logo,
  logoTexto: logoCBHBTexto,
  footerBG: footerBG,
  logoBigmidia: logoBigmidia,
  favicon: favicon,
  emailContato: "fphand@fphand.com.br",
  redesSociais: [
    {
      name: "facebook",
      url: "https://www.facebook.com/fphandebol/",
      btnClass: "btn-social-counter--fb",
      texto: "Curta nossa página",
    },
    {
      name: "twitter",
      url: "https://twitter.com/fphand",
      btnClass: "btn-social-counter--twitter",
      texto: "Siga-nos no Twitter",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/oficialfphand/",
      btnClass: "btn-social-counter--instagram",
      texto: "Siga-nos no Instagram",
    },
  ],
};
