export const menuFPHAND = [
  {
    label: "FPHB",
    url: "#!",
    filho: [
      { label: "ÓRGÃOS DIRETIVOS", url: "" },
      { label: "FILIADOS", url: "" },
      { label: "COMO SE FILIAR", url: "/fphb/198/como-se-filiar" },
      { label: "CONTATO", url: "/contato" },
      // { label: "BALANCETES", url: "" },
      // { label: "TAXAS ADMINISTRATIVAS", url: "" },
      // { label: "TAXAS TJD", url: "" },
      // { label: "TAXAS DE ARBITRAGEM", url: "" },
      // { label: "TAXAS DE DESLOCAMENTO", url: "" },
      // { label: "TRIBUNAL DE JUSTIÇA DESPORTIVA", url: "" },
      // { label: "COMPOSIÇÃO DO TJD", url: "" },
      // { label: "CONSULTA AOS PROCESSOS", url: "" },
      { label: "MINI-HANDEBOL", url: "" },
      // { label: "ARBITRAGEM", url: "" },
      // { label: "HANDEBOL NEWS", url: "" },
      { label: "DEPARTAMENTO CIENTÍFICO PEDAGÓGICO", url: "" },
      { label: "BEACH HANDBALL", url: "" },
      // { label: "BIBLIOTECA ", url: "" },
      // { label: "POLÍTICA DE PRIVACIDADE", url: "" },
    ],
  },
  {
    label: "CAMPEONATOS",
    url: "/eventos",
    filho: null,
  },
  {
    label: "NOTÍCIAS",
    url: "/noticias",
    filho: null,
  },
  {
    label: "FOTOS",
    url: "/galeria",
    filho: null,
  },
  // {
  //   label: " MATCH CENTER",
  //   url: "#!",
  //   filho: [
  //     { label: "JOGOS", url: "" },
  //     { label: "CLASSIFICAÇÃO", url: "" },
  //     { label: "ARTILHARIA", url: "" },
  //     { label: "PRÓXIMOS JOGOS", url: "" },
  //     { label: "LOCAIS DOS JOGOS", url: "" },
  //   ],
  // },
];
